@font-face {
  font-family: 'RelativeMono';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/relative/relative-book-pro.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/relative/relative-book-pro.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/relative/relative-book-pro.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/relative/relative-book-pro.woff') format('woff'), /* Modern Browsers */
       url('./fonts/relative/relative-book-pro.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'RelativeMono';
  font-style: bold;
  font-weight: 500;
  src: url('./fonts/relative/relative-bold-pro.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/relative/relative-bold-pro.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/relative/relative-bold-pro.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/relative/relative-bold-pro.woff') format('woff'), /* Modern Browsers */
       url('./fonts/relative/relative-bold-pro.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Black.woff2') format('woff2'),
      url('./fonts/inter/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Bold.woff2') format('woff2'),
      url('./fonts/inter/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-ExtraBold.woff2') format('woff2'),
      url('./fonts/inter/Inter-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Light.woff2') format('woff2'),
      url('./fonts/inter/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-SemiBold.woff2') format('woff2'),
      url('./fonts/inter/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Medium.woff2') format('woff2'),
      url('./fonts/inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Thin.woff2') format('woff2'),
      url('./fonts/inter/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Regular.woff2') format('woff2'),
      url('./fonts/inter/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-ExtraLight.woff2') format('woff2'),
      url('./fonts/inter/Inter-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "etna";
  src: local("etna"),
   url("./fonts/etna.otf") format("truetype");
  font-weight: normal;
}

body {
  font-size: 0.9rem;
  /* background: #f6f9ff; */
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.recharts-wrapper {
  font-size: 0.8rem;
  font-family: 'Inter', monospace;
}

.recharts-legend-item-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.App {
  color: #000;
  background: #fcfcfc;
  line-height: 1.15;
  padding: 1rem;
  padding-top: 0;
}

.App.dark {
  background: #01011b;
  color: #FFF;
  line-height: 1.15;
  box-sizing: border-box;
  min-height: 100vh;
}

h1{
  font-family:"etna";
}

h3, h2 {
  color: #444;
  margin: 1rem 0 1.5rem;
}

.App.dark h3, .App.dark h2 {
  color: white;
}

h3 {
  margin: 1rem 0;
  font-weight: normal;
  font-size: 1.2rem;
}

h2 {
  font-size: 1.6rem;
  font-weight: normal
}

label {
  cursor: pointer;
}

input + label,
label + input {
  margin-left: 0.5rem;
}

.chart-subtitle, .stats {
  margin: 1.5rem 0 1rem;
}

.chart-description {
  margin: 1rem 0 0;
  max-width: 600px;
  /* color: #ffffff; */
}

.App.dark .chart-description {
  color: #ffffff;
}

.chart-description p:first-child {
  margin-top: 0;
}

.chart-description p:last-child {
  margin-bottom: 0;
}

.chart-description ul {
  margin: 0;
  padding-left: 20px;
}

.chart-description li {
  margin-top: 5px;
}

.chart-grid {
  color: #16182E;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.chart-grid-subtitle {
  grid-column-start: 1;
  grid-column-end: 9;
  margin-bottom: 0;
  margin-top: 2rem;
}

.total-stat-value {
  font-size: 1.5rem;
  overflow: hidden;
  white-space: pre-wrap;
}

.total-stat-delta {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: block;
}

.total-stat-delta.plus {
  color: #46E3AE;
}
.App.light .total-stat-delta.plus {
  color: rgb(34 199 97);
}
.total-stat-delta.minus {
  color: #727171;
}

.App.dark .chart-cell {
  background: #181818;
  border: none;
  color: white;
}

.App.dark .chart-cell.stats {
  border: 1px solid #FFFFFF14;
}

.chart-cell {
  margin: 0;
  border-radius: 15px;
  border: 1px solid #FFFFFF14;
  background: #181818;
  position: relative;
  padding: 1rem;
  grid-column-start: span 5;
}

.chart-cell.stats {
  min-height: 60px;
  grid-column-start: span 2;
  text-align: center;
}

.chart-cell{
  box-shadow: 0 5px 22px rgba(0,0,0,.12156862745098039);
  box-shadow: 0 12px 17px rgba(0,0,0,.1411764705882353);
  box-shadow: 0 7px 8px rgba(0,0,0,.2);
  border: 1px solid hsla(0,0%,100%,.0784313725490196);
  border-radius: 15px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background:#12143b!important;
}

.chart-cell.experiment {
  border-color: #fbb;
}
.chart-cell.experiment:after {
  color: #f55;
  top: 1rem;
  right: 1rem;
  position: absolute;
  content: '(experimental)';
}

.chart-cell h3 {
  margin-top: 0;
  letter-spacing: 0px;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

.chart-cell .csv-link {
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 0.3rem;
  opacity: 0.5;
  display: inline-flex;
  vertical-align: middle;
}
.chart-cell:hover .csv-link {
  opacity: 0.5;
}
.chart-cell .csv-link:hover {
  opacity: 1;
}

.form input[type="date"],
.form input[type="text"] {
  border: 1px solid #aaa;
  border-radius: 3px;
  appearance: none !important;
  box-sizing: border-box;
  padding: 3px;
}

.form button {
  border: none;
  appearance: none !important;
  color: #000;
  border: 1px solid #FFFFFF0F;
  box-sizing: border-box;
  background: #b39a31;
  cursor: pointer;
  margin-left: 10px;
  height: 28px;
  border-radius: 3px;
  padding: 3px 7px;
}
.form button:hover {
  opacity: 0.9;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: loader 1.5s infinite;
  opacity: 0.3;
}
.chart-cell .loader {
  position: absolute;
  margin-left: -1.5em;
  margin-top: -1.5em;
  font-size: 0.7rem;
  top: 50%;
  left: 50%;
  z-index: 2;
}


.warning, .warning a {
  color: #e46b00;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -1rem 0;
  height: 62px;
  border-bottom: 1px solid #ffffff;
  background-color: white;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  background: transparent 0 0 no-repeat padding-box;
  border-bottom: 1px solid hsla(0,0%,100%,.06274509803921569);
}

.nav .navInner{
  max-width:90%;
  margin:auto auto;
}

.App.dark .nav {
  background: #01011b;
  height: 62px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  background: transparent 0 0 no-repeat padding-box;
  border-bottom: 1px solid hsla(0,0%,100%,.06274509803921569);
}

.nav-logo img {
  width: 87px;
}

a, a:active, a:visited {
  color: #16182E;
}

.nav-link {
  letter-spacing: 0.47px;
  color: #ffffff;
  /* color: #000; */
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.125rem;
  letter-spacing: 0.47px;
}

.nav-link:active, .nav-link:visited {
  color: #ffffff;
}

.nav-link.active {
  color: #444;
}

.App.dark a, .App.dark a:active, .App.dark a:visited {
  color: #FFFFFF;
}

.App.dark .nav-link {
  color: #ffffff;
}

.App.dark .nav-link:active, .App.dark .nav-link:visited {
  color: #ffffff;
}

.App.dark .nav-link.active {
  color: white;
}

.App.dark .nav-link:hover {
  color: white;
}

.nav-link:hover {
  color: #444;
}

.nav-right {
  display: flex;
  align-items: center;
}

.modeselect {
  width: 30px;
  height: 26px;
  background: #16182E 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF0F;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.App.dark .modeselect {
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.modeselect svg {
  fill: #ffffff;
}

.App.dark .modeselect svg {
  fill: #FFFFFF0F;
}

.page-title {
  font-size: 1.8125rem;
  line-height: 2.3125rem;
}

.recharts-cartesian-axis-tick-value {
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-axis-tick-value {
  fill: #fff;
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-grid-horizontal line, .App.dark .recharts-cartesian-grid-vertical line {
  stroke: #FFFFFF0F;
}

.recharts-tooltip-wrapper{
  background: #12143bde;
}

.recharts-tooltip-wrapper .recharts-default-tooltip {
  padding: 5px 12px!important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li {
  padding: 0px!important;
  font-size: 12px!important;
  line-height: 15px!important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li + li {
  margin-top: 1px!important;
}

.App.dark .recharts-tooltip-wrapper .recharts-default-tooltip {
  background-color: #00000029!important;
  box-shadow: 0px 3px 6px #0000005C;
  border: 1px solid #484B6E!important;
  border-radius: 4px;
}

.App-header-drawer {
  background: rgba(4,0,70,.5333333333333333) 0 0 no-repeat padding-box;
  box-shadow: 8px 3px 6px rgba(0,0,0,.1607843137254902);
  opacity: 1;
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 304px;
}

.App-header-drawer .App-header-menu-icon-block{
  width: 25px;
    margin-right: 20px;
}

.App-header-drawer .App-header-link-container a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #ffffff;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
}

.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: rgba(48,63,208,.1411764705882353);
  color: white;
}

.App-header-drawer .App-header-menu-icon {
  color: white;
  margin: unset;
  font-size: 2rem;
}

.App-header-drawer .App-header-link-main {
  display: flex;
  align-items: center;
}

.App-header-links-header {
  height: 62px;
  display: flex;
  align-items: center;
  z-index: 3;
  padding-left: 1rem;
  padding-right: 1rem;
}

.App-header-menu-icon {
  color: black;
  font-size: 1.3rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 9px 10px;
}

.App.dark .App-header-menu-icon {
  color: white;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

@media all and (max-width: 1000px) {
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .chart-cell {
    grid-column-start: span 2;
    grid-column-end: auto;
  }
  .chart-cell.stats {
    grid-column-start: span 1;
  }
}

.content {
  max-width:90%;
  margin:auto auto;
  margin-top: 2rem;
}

.App-header-menu-icon-block {
  display: none;
}

.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media all and (max-width: 600px) {
  .chart-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .chart-cell {
    grid-column-start: unset!important;
    grid-column-end: unset!important;
  }

  .App-header-menu-icon-block {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  .nav-left {
    display: flex;
    align-items: center;
  }

  .nav-logo {
    display: flex;
  }

  .nav-left .nav-link {
    display: none;
  }
}
